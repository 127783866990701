import React from "react";
import TimerAPp from "../images/portfolio/time-app.png";
import TodoScheduler from "../images/portfolio/todo-scheduler.png";

export default function Portfolio() {
  return (
    <div id="portfolio" class="text-center paddsection">
      <div class="container">
        <div class="section-title text-center">
          <h2>My Portfolio</h2>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="portfolio-container">
              <div class="col-lg-4 col-md-6 portfolio-thumbnail all branding uikits webdesign">
                <a href="https://timer-app.pages.dev/">
                  Countdown Timer
                  <img src={TimerAPp} alt="img" />
                </a>
              </div>
              <div class="col-lg-4 col-md-6 portfolio-thumbnail all branding uikits webdesign">
                <a href="https://todo-scheduler.pages.dev/">
                  Todo Scheduler
                  <img src={TodoScheduler} alt="img" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/*

Not enough category to skipping this filter of applications

<div class="portfolio-list">
              <ul class="nav list-unstyled" id="portfolio-flters">
                <li class="filter filter-active" data-filter=".all">
                  all
                </li>
                <li class="filter" data-filter=".branding">
                  branding
                </li>
                <li class="filter" data-filter=".mockups">
                  mockups
                </li>
                <li class="filter" data-filter=".uikits">
                  ui kits
                </li>
                <li class="filter" data-filter=".webdesign">
                  web design
                </li>
                <li class="filter" data-filter=".photography">
                  photography
                </li>
              </ul>
            </div>

*/
