import "./App.css";
import "./css/blog.css";
import "./css/responsive.css";
import "./css/style.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Portfolio from "./components/Portfolio";

function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <Header></Header>
      <About></About>
      <Portfolio></Portfolio>
    </div>
  );
}

export default App;
