import React from "react";
import Me from "../images/me.jpeg";
import JsImg from "../images/js.svg";
import HtmlImg from "../images/html.svg";
import CssImg from "../images/css.svg";
import ReactImg from "../images/reactjs.svg";
import VueImg from "../images/vuejs.svg";
import NodeImg from "../images/nodejs.svg";
import ReduxImg from "../images/redux.svg";
import PythonImg from "../images/python.svg";
import GithubImg from "../images/github.svg";
import DockerImg from "../images/docker.webp";
import AWSImg from "../images/aws.svg";
import GCPImg from "../images/gcp.svg";
import MongoImg from "../images/mongodb.svg";
import MysqlImg from "../images/mysql.png";

export default function About() {
  return (
    <div id="about" class="paddsection">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-4 ">
            <div class="div-img-bg">
              <div class="about-img">
                <img src={Me} class="img-responsive" alt="me" />
              </div>
            </div>
          </div>

          <div class="col-lg-7">
            <div class="about-descr">
              <p class="p-heading">
                With over 11 years of experience as a Full Stack Developer, I
                have a proven track record of delivering innovative solutions
                that drive business growth. I am an expert in utilising a wide
                range of technologies to create seamless user experiences and
                have a deep understanding of the full software development
                lifecycle.
              </p>

              <p class="separator">
                My passion for technology and drive for continuous improvement
                allows me to stay ahead of the latest trends and deliver
                high-performance web applications. I possess strong
                communication and collaboration skills and lead cross-functional
                teams effectively from conception to deployment. I have proven
                track record to design and build robust, scalable, and secure
                systems that meet the changing needs of businesses.
              </p>

              <p class="separator">
                My problem-solving skills, ability to adapt to new technologies,
                and strong understanding of server-side (NodeJS, PHP) make me an
                asset to any team. I have a strong understanding of web design
                principles and am able to create visually appealing and
                user-friendly websites. My strong problem-solving skills and
                ability to adapt to new technologies make me an asset to any
                team. Whether you're looking to build a new website from scratch
                or need to update an existing one, I have the knowledge and
                experience to get the job done. I've working experience in
                following tech stack Javascript | ReactJS | VueJS | NodeJS |
                EmberJS | Typescript | Gitlab CI/CD | Github Actions | MySQL |
                MariaDB | MongoDB | Redux | Vuex | Vuetify | Docker | AWS |
                Google Cloud
              </p>
              <p class="separator"></p>
              <p class="separator"></p>
            </div>
          </div>
        </div>
        <div className="skills-container">
          <img src={JsImg} width="48" alt="javascript" height="48" />
          <img src={HtmlImg} alt="html" width="48" height="48" />
          <img src={CssImg} alt="css" width="48" height="48" />
          <img src={ReactImg} alt="react" width="48" height="48" />
          <img src={VueImg} alt="vue" width="48" height="48" />
          <img src={NodeImg} alt="nodejs" width="48" height="48" />
          <img src={ReduxImg} alt="redux" width="48" height="48" />
          <img src={PythonImg} alt="python" width="48" height="48" />
          <img src={GithubImg} alt="github" width="48" height="48" />
          <img src={DockerImg} alt="docker" width="48" height="48" />
          <img src={AWSImg} alt="aws" width="48" height="48" />
          <img src={GCPImg} alt="gcp" width="48" height="48" />
          <img src={MongoImg} alt="mongo" width="48" height="48" />
          <img src={MysqlImg} alt="mysql" width="48" height="48" />
        </div>
      </div>
    </div>
  );
}
