import React from "react";

export default function Header() {
  return (
    <div id="header" className="home">
      <div className="container">
        <div className="header-content">
          <h1>
            I'm <span className="typed"></span>
          </h1>
          <p>Developer, Dreamer, Curious</p>

          <ul className="list-unstyled list-social">
            <li>
              <a href="https://www.linkedin.com/in/prabodhmeshram/">
                <i className="ion-social-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="https://www.github.com/prabodhmeshram/">
                <i className="ion-social-github"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
